// radiuses
@rad-big: 8px;
@rad-mid: 5px;
@rad-sml: 3px;

// colors
@clr-blk: #262626;
@clr-gry: #bfbfbf;
@clr-wht: #f5f5f5;
@clr-red: #ff4d4f;
@clr-grn: #73d13d;
@clr-blu: #40a9ff;

// font sizes
@fnt-sml0: 8px;
@fnt-sml1: 10px;
@fnt-mid0: 12px;
@fnt-mid1: 14px;
@fnt-big0: 18px;
@fnt-big1: 24px;

// responsive breakpoints
@res-xs: 480px;
@res-sm: 576px;
@res-md: 768px;
@res-lg: 991px;
@res-xl: 1200px;
@res-xxl: 1600px;

.cellular(@rules) {
  @media (max-width: @res-md) {
    @rules();
  }
}

.mobile(@rules) {
  @media (max-width: @res-lg) {
    @rules();
  }
}

.laptop(@rules) {
  @media (min-width: @res-xl) {
    @rules();
  }
}

.desktop(@rules) {
  @media (min-width: 1440px) {
    @rules();
  }
}

.bigscreen(@rules) {
  @media (min-width: @res-xxl) {
    @rules();
  }
}

.hugescreen(@rules) {
  @media (min-width: 2250px) {
    @rules();
  }
}
