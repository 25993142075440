.row {
  padding: 10px 0;
}

.logo {
  height: 120px;
  padding-top: 20px;
}

.main {
  padding: 20px;
  min-width: 344px;
}

