.ant {
  &-layout {
    height: 100%;
    display: block;
    background: none;

    &&-has-sider > &,
    &&-has-sider > &-content {
      width: auto;
    }

    &-sider {
      height: 100%;
      position: fixed;
      z-index: 99;
      left: 0;
      box-shadow: 1px 0 5px rgba(150, 150, 150, 0.1);
      border-right: 1px solid #f5f5f5;

      &-children {
        overflow-y: auto;
      }

      &-zero-width-trigger {
        z-index: 99;
      }

      &-light {
        background-color: #fcfcfc;

        & .ant-layout-sider-zero-width-trigger {
          top: 340px;
          box-shadow: 3px 0 4px rgba(150, 150, 150, 0.2);
        }
      }

      &-collapsed {
        box-shadow: none;
      }
    }
  }

  &-alert {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &-list-bordered &-list-header,
  &-list-bordered &-list-item {
    padding-left: 16px;
    padding-right: 16px;
  }

  &-input-number-affix-wrapper,
  &-input-number,
  &-picker {
    width: 100% !important;
  }

  &-tree &-tree-treenode {
    padding-bottom: 5px;
    padding-top: 2px;
  }

  &-typography {
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6& {
      font-weight: 400;
    }
  }

  &-descriptions {
    &-row > th {
      font-size: 6px;
      padding-bottom: 1px;
    }
    &-row > td {
      padding-bottom: 12px;
    }
    &-item-content .ant-image {
      margin: 10px 10px 7px 0;
    }
  }

  &-form {
    .text-center &-item-label {
      text-align: center;
    }
  }

  &-menu {
    &-inline {
      border-right-color: rgba(0, 0, 0, 0) !important;
    }
  }

  &-btn-icon-only {
    line-height: 30px;
  }

  &-divider {
    .shkt-header &-inner-text {
      padding-left: 0;
      padding-right: 10px;
    }
  }

  &-drawer-right.ant-drawer-open &-drawer-content-wrapper {
    @media (min-width: 1860px) {
      box-shadow: 1px 0 5px rgb(150 150 150 / 10%);
      border-left: 1px solid #f5f5f5;
    }
  }

  &-list {
    &-item {
      &-meta {
        .ant-list-vertical & {
          margin-bottom: 0;
        }

        &-title {
          margin-bottom: 7px !important;
        }
        &-content h4 {
          line-height: 19px !important;
        }
      }
    }
  }

  .shkt-drawer &-btn-icon-only {
    line-height: 28px;
    min-width: 30px;
    width: 30px;
    height: 30px;
  }
}

.product .ant-card-cover {
  border: 1px solid #f0f0f0;
  border-bottom: none;
}

.market-order .ant-list-header {
  padding: 0;
}

.collapse-list .ant-collapse-item > .ant-collapse-header,
.collapse-list .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}

.collapse-list .ant-list-items .ant-list-item {
  align-items: center !important;
}

.dashed-list-border .ant-list-items .ant-list-item {
  border-bottom: 1px dashed #ddd;
}

.dashed-list-border .ant-list-items .ant-list-item:last-child {
  border-bottom: none !important;
}

.ant-menu-inline.category-menu .ant-menu-item {
  margin-top: 2px;
}
.ant-menu-inline.category-menu .ant-menu-item:not(:last-child) {
  margin-bottom: 2px;
}

.category-select .ant-select-tree-indent-unit {
  width: 11px !important;
}

.category-select .ant-select-tree-switcher {
  width: 15px !important;
}

.anticon-spin {
  animation: loadingCircle 0.5s infinite linear;
}
