@import '../../assets/vars.less';

.filler {
  width: 46px;
}

.rightbox {
  .laptop({
    text-align: right;
  });
}

.qty {
  font-weight: 400;
}

.qty2 {
  font-weight: 500;
}

.numbers {
  margin-top: 13px;
  margin-bottom: 4px;
}

.listtitle {
  margin-bottom: 7px;
}

.top {
  width: 100%;
  margin-top: 2px;
}

.bott {
  margin-top: 3px;
}

.block {
  width: 100%;
}

.panel {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-right: 0 !important;
  margin-left: 0 !important;

  &:hover {
    background-color: #f8f9fa;
  }
}

.stocks {
  padding-left: 10px;
  padding-right: 10px;
}

.label {
  font-weight: 400;
  font-size: 15px;
}

.varideets {
  font-size: 14px;
}

.byvariation {
  cursor: auto;
}

.ledge {
  margin-bottom: 10px;
}

.ledgetype {
  margin-right: 0;
}

