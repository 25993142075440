.divider {
  overflow: hidden;
  margin: 0 !important;
}

.title {
  margin-bottom: 2px !important;
}

.extra {
  padding: 0 0 0 10px;
}

