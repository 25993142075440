.ordercompbox {
  margin-top: 15px;
}

.primary {
  color: #006fd6;
}

.qty {
  font-weight: 400;
}

