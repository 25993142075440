html,
body,
#root {
  height: 100%;
  margin: 0;
  background-color: #fcfcfc;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
a,
button {
  transition: all 0.3s ease-in-out;
}
.text-center {
  text-align: center;
}
.text-white {
  color: #fff;
}
.cursor-pointer {
  cursor: pointer;
}
.lisht-content {
  margin-bottom: 10px !important;
}
@media (max-width: 768px) {
  .lisht-content {
    margin-bottom: 0 !important;
  }
}
.no-top-margin {
  margin-top: 0 !important;
}
.no-bott-margin {
  margin-bottom: 0 !important;
  line-height: 20px;
}
.no-right-margin {
  margin-right: 0 !important;
}
.market-card .ant-card-body {
  padding: 12px 14px;
}
.ant-layout {
  height: 100%;
  display: block;
  background: none;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: auto;
}
.ant-layout-sider {
  height: 100%;
  position: fixed;
  z-index: 99;
  left: 0;
  box-shadow: 1px 0 5px rgba(150, 150, 150, 0.1);
  border-right: 1px solid #f5f5f5;
}
.ant-layout-sider-children {
  overflow-y: auto;
}
.ant-layout-sider-zero-width-trigger {
  z-index: 99;
}
.ant-layout-sider-light {
  background-color: #fcfcfc;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  top: 340px;
  box-shadow: 3px 0 4px rgba(150, 150, 150, 0.2);
}
.ant-layout-sider-collapsed {
  box-shadow: none;
}
.ant-alert {
  padding-top: 4px;
  padding-bottom: 4px;
}
.ant-list-bordered .ant-list-header,
.ant-list-bordered .ant-list-item {
  padding-left: 16px;
  padding-right: 16px;
}
.ant-input-number-affix-wrapper,
.ant-input-number,
.ant-picker {
  width: 100% !important;
}
.ant-tree .ant-tree-treenode {
  padding-bottom: 5px;
  padding-top: 2px;
}
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  font-weight: 400;
}
.ant-descriptions-row > th {
  font-size: 6px;
  padding-bottom: 1px;
}
.ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-item-content .ant-image {
  margin: 10px 10px 7px 0;
}
.text-center .ant-form-item-label {
  text-align: center;
}
.ant-menu-inline {
  border-right-color: rgba(0, 0, 0, 0) !important;
}
.ant-btn-icon-only {
  line-height: 30px;
}
.shkt-header .ant-divider-inner-text {
  padding-left: 0;
  padding-right: 10px;
}
@media (min-width: 1860px) {
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 1px 0 5px rgba(150, 150, 150, 0.1);
    border-left: 1px solid #f5f5f5;
  }
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 0;
}
.ant-list-item-meta-title {
  margin-bottom: 7px !important;
}
.ant-list-item-meta-content h4 {
  line-height: 19px !important;
}
.shkt-drawer .ant-btn-icon-only {
  line-height: 28px;
  min-width: 30px;
  width: 30px;
  height: 30px;
}
.product .ant-card-cover {
  border: 1px solid #f0f0f0;
  border-bottom: none;
}
.market-order .ant-list-header {
  padding: 0;
}
.collapse-list .ant-collapse-item > .ant-collapse-header,
.collapse-list .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.collapse-list .ant-list-items .ant-list-item {
  align-items: center !important;
}
.dashed-list-border .ant-list-items .ant-list-item {
  border-bottom: 1px dashed #ddd;
}
.dashed-list-border .ant-list-items .ant-list-item:last-child {
  border-bottom: none !important;
}
.ant-menu-inline.category-menu .ant-menu-item {
  margin-top: 2px;
}
.ant-menu-inline.category-menu .ant-menu-item:not(:last-child) {
  margin-bottom: 2px;
}
.category-select .ant-select-tree-indent-unit {
  width: 11px !important;
}
.category-select .ant-select-tree-switcher {
  width: 15px !important;
}
.anticon-spin {
  animation: loadingCircle 0.5s infinite linear;
}
