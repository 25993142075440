.userbox {
  width: 140px;
  margin: 0 auto;
}

.usertext {
  margin-top: 10px;
}

.rolebox {
  width: 100%;
}

.userstar {
  font-size: 22px !important;
  color: #fc3;
}

