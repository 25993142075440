@import '../../assets/vars.less';

.more {
  background-color: #fff;
  border-radius: 36px;
  padding: 5px 10px 3px;
  color: #666;
}

.pagination {
  text-align: right;
}

.sort {
  min-width: 120px;
  width: 100%;
}

.category {
  font-size: 13px;
}

.name {
  font-weight: 500;
}

.company {
  font-size: 13px;
}

.bold {
  font-weight: 500;
}

.price {
  margin-top: 8px;
}

.bigprice {
  font-size: 28px;
  line-height: 28px !important;
  margin-bottom: 0 !important;
}

.bigproduct {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px !important;
}

.quote {
  border-left: 4px solid #fafafa !important;
}

.primary {
  color: #006fd6;
}

.header {
  font-weight: 400;
}

.subbtn {
  width: 25px;
  height: 25px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
}

.back {
  width: 25px;
  height: 25px;
  line-height: 25px;
  min-width: 25px !important;

  &:focus {
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
  }
}

.box {
  margin-bottom: 10px;
}

.crumb {
  margin-bottom: 15px;
}

.marketbtn {
  padding: 4px;
  width: 33px;
  height: 33px;
}

.under {
  transform: translate(-25px, -2px);
}

.icon {
  transform: translate(0, -1px);
}

.spinner {
  font-size: 25px;
}

.picture {
  border: 1px solid #f0f0f0;
}

.picbuttons {
  margin-top: 12px;
  text-align: center;
}

.cartbuttons {
  margin-top: 15px;
}

.variation {
  margin-top: 12px;
  margin-left: 12px;
}

.option {
  margin-bottom: 7px;
}

.nobg {
  background: none;
}

.cartcompbox {
  margin-bottom: 15px;
}

.total {
  font-weight: 500;
  font-size: 16px;
}

.cartcompbox,
.ordercompbox {
  margin-top: 15px;
}

.collapsemenu {
  font-size: 16px;
  margin: 100px 0 0 100px;
}

.collapseheader {
  :global {
    .ant-collapse-header {
      padding-bottom: 10px !important;
    }
  }
}

