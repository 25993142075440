@import '../../assets/vars.less';

.list {
  margin-top: 10px;
}

.listmeta {
  margin-bottom: 0;
}

.bottominfo {
  font-size: 13px;
  margin-top: 10px;
}

.pagination {
  text-align: right;
}

.sort {
  width: 100%;
  min-width: 120px;
}

.listitem {
  transition: all 0.2s ease-in-out;
  padding-left: 10px;

  .mobile({
    padding-left: 0;
    background-color: none;
  });
}

.listitemhover {
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
  }
}

.nopadd {
  padding: 0 !important;
}

.paginationMobile {
  :global {
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev,
    .ant-pagination-item:not(.ant-pagination-item-active) {
      display: none;
    }
    .ant-pagination-item-active {
      font-weight: 500;
      border-color: transparent;
      padding: 4px 10px;
    }
  }
}

