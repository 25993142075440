html,
body,
#root {
  height: 100%;
  margin: 0;
  background-color: #fcfcfc;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

a,
button {
  transition: all 0.3s ease-in-out;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

.lisht-content {
  margin-bottom: 10px !important;

  .cellular({
    margin-bottom: 0 !important;
  });
}

.no-top-margin {
  margin-top: 0 !important;
}

.no-bott-margin {
  margin-bottom: 0 !important;
  line-height: 20px;
}

.no-right-margin {
  margin-right: 0 !important;
}

.market-card {
  .ant-card-body {
    padding: 12px 14px;
  }
}
