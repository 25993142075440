.cover {
  margin: 20px;
}

.crumb {
  margin-left: 20px;
}

.isForSale {
  right: 13px !important;
  top: 20px !important;
}

.divider {
  color: #eee;
  margin-left: 5px;
  margin-right: 5px;
}

.variation {
  font-weight: 500;
  font-size: 14px;
}

.id {
  font-weight: 400;
}

