.profileavatar {
  margin: 20px auto 10px;
}

.profileminform {
  max-width: 302px;
}

.profileemailres {
  padding-left: 0;
  padding-right: 0;
}

