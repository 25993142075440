table {
  width: 100%;
  border-collapse: collapse;
 }
 
 th,
 td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
 }
 
 th {
  background-color: #f2f2f2;
 }

