.divider {
  color: #eee;
  margin-left: 5px;
  margin-right: 5px;
}

.price {
  font-weight: 500;
}

.top {
  width: 100%;
  margin-top: 2px;
}

.bott {
  width: 100%;
  margin-top: 3px;
}

.panel {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-right: 0 !important;
  margin-left: 0 !important;

  &:hover {
    background-color: #f8f9fa;
  }
}

.label {
  font-weight: 400;
  font-size: 15px;
}

.prices {
  padding-left: 10px;
  padding-right: 10px;
}

.created {
  font-size: 13px;
}

