.usercivil {
  margin-right: 17px;
}

.userbday {
  padding-right: 17px;
  width: 100%;
}

.userbdayin {
  width: 100%;
}

.userstar {
  font-size: 16px;
  color: #fc3;
}

.userlist {
  color: #999;
}

.usernone {
  color: #ddd;
}

.usersus {
  font-size: 11px;
}

.userrolename {
  max-width: 130px;
}

.userstatus {
  font-size: 16px;
}

