.avatar {
  margin-right: 5px;
  margin-left: 5px;
}

.usersbox {
  width: 130px;
  margin: 0 auto;
}

.usersname {
  margin-top: 10px;
}

.name {
  font-size: 15px !important;
}

