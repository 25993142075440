@import '../../assets/vars.less';

.row {
  padding: 10px 0;
}

.logo {
  height: 80px;
}

.profile {
  display: block;
  padding: 10px 5px;
  margin: -10px 7px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  .desktop({
    margin: -10px 15px 0;
  });

  &:hover {
    background-color: #f9f9f9;
  }
}

.registerlogin {
  display: block;
  padding: 13px 5px;
  margin: -10px 7px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  .desktop({
    margin: -10px 15px 0;
  });
}

.company {
  display: block;
  padding: 10px 5px;
  margin: -10px 7px 11px;
  border-bottom: 1px solid #eee;

  .desktop({
    margin: -10px 15px 11px;
  });

  &:hover {
    background-color: #f9f9f9;
  }
}

.deets {
  width: 128px;
  margin-left: 4px;
  line-height: 19px;

  .desktop({
    width: 175px;
  });
}

.layout {
  display: absolute;
  z-index: 1;

  .hugescreen({
    padding-right: 500px;
  });
}

.main {
  margin-left: 200px;
  padding: 20px;

  .mobile({
    margin-left: 0;
    padding: 20px 20px 100px;
  });

  .desktop({
    margin-left: 260px;
    max-width: 992px;
  });

  .bigscreen({
    margin: 0 auto 0 270px;
    max-width: 1200px;
  });

  .hugescreen({
    margin: 0 auto;
  });
}

.foot {
  margin: 0 7px;
  padding-top: 40px;
  border-top: 1px solid #eee;
  text-align: center;

  .desktop({
    margin: 0 15px;
  });
}

.spin {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1001;
}

.spinner {
  font-size: 24px;
}

.drawerhead {
  margin-bottom: 30px;
}

.drawertitle {
  margin-bottom: 2px;
}

